import { combineReducers } from 'redux';
import cartReducer from './cartReducer';
import createOrderReducer from './createOrderReducer';
import donationReducer from './donationReducer';
import payOrderReducer from './payOrderReducer';
import paymentReducer from './paymentReducer';
import promoCodeReducer from './promoCodeReducer';
import registrationReducer from './registrationReducer';
import checkAtendeeReducer from './checkAtendeeReducer';
import checkPurchaseStatusReducer from './checkPurchaseStatus';
import setupOfflinePaymentReducer from './setupOfflinePaymentReducer';

export default combineReducers({
  cart: cartReducer,
  createOrder: createOrderReducer,
  donation: donationReducer,
  payOrder: payOrderReducer,
  setupOfflinePayment: setupOfflinePaymentReducer,
  payment: paymentReducer,
  promoCode: promoCodeReducer,
  registration: registrationReducer,
  checkAttendee: checkAtendeeReducer,
  checkPurchaseStatus: checkPurchaseStatusReducer,
});
