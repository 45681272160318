import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { PaymentMethodType } from '../types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<PaymentMethodType>();
const initialState = stateFactory.getInitialState({
  tempSpreedlyPaymentMethod: null,
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const paymentMethodsReducer = createReducer<State, Actions>(initialState, {
  [actions.getPaymentMethods.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getPaymentMethods.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getPaymentMethods.failure.type]: actionsHandlers.handleGetManyFailure,

  [actions.createPaymentMethods.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createPaymentMethods.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createPaymentMethods.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createPaymentMethods.reset.type]: actionsHandlers.handleCreateOneReset,

  [actions.updatePaymentMethods.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updatePaymentMethods.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.updatePaymentMethods.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updatePaymentMethods.success.type]: (draft, action) => {
    const { params, entity } = action.payload;
    Object.keys(draft.byId).forEach((id) => {
      const paymentMethod = draft.byId[id];
      if (paymentMethod.id === params.payment_method_id) {
        paymentMethod.billing_details = entity.billing_details;
        entity.id = paymentMethod.id;
      }
      if (params.is_default) {
        paymentMethod.is_default = paymentMethod.id === params.payment_method_id;
      }
    });
    actionsHandlers.handleUpdateOneSuccess(draft, action);
  },

  [actions.deletePaymentMethod.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deletePaymentMethod.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deletePaymentMethod.failure.type]: actionsHandlers.handleDeleteOneFailure,

  [actions.setTempPaymentMethod.type]: (draft, action) => {
    draft.tempPaymentMethod = action.payload.paymentMethod;
  },
  [actions.resetTempPaymentMethod.type]: (draft) => {
    draft.tempPaymentMethod = null;
  },
  [actions.setDefaultPaymentMethod.success.type]: (draft, action) => {
    const { params } = action.payload;

    Object.keys(draft.byId).forEach((id) => {
      const paymentMethod = draft.byId[id];

      if (paymentMethod.id === params.payment_method_token) {
        paymentMethod.is_default = true;
      } else {
        paymentMethod.is_default = false;
      }
    });
  },
});

export default paymentMethodsReducer;
