import * as Yup from 'yup';
import { TFunction } from 'next-i18next';
import { isValid, parseISO, subDays, subYears } from 'date-fns';

export const emailSchema = (message?: string) => {
  const userRegex = /^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*$/i;
  const domainRegex = /^((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+)(?:[A-Z0-9-]{2,63})$/i;

  return Yup.string()
    .trim()
    .max(128)
    .test({
      name: 'email',
      message: message || (() => (t: TFunction) => t('common:field.validation.string.email')),
      test: function test(value) {
        if (!value) return true;
        if (typeof value !== 'string') return false;

        const index = value.indexOf('@');
        if (index === -1) return false;

        const userPart = value.slice(0, index);
        const domainPart = value.slice(index + 1);

        // since safari does not support negative lookbehind we added additional check of the domain part
        return userRegex.test(userPart) && domainRegex.test(domainPart) && !domainPart.endsWith('-');
      },
    });
};

// eslint-disable-next-line default-param-last
export const postalCodeSchema = (countryCodeName = 'country', message?: string) => {
  const regexByCountryCode: Record<string, RegExp> = {
    CA: /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) *(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i,
    US: /^\d{5}(?:-\d{4})?$/,
  };

  return Yup.string().when(countryCodeName, (countryCode: string, schema: Yup.StringSchema) => {
    if (countryCode) {
      return schema.test({
        name: 'postalCode',
        message:
          message || (() => (t: TFunction) => t('common:field.validation.mixed.postal_code', { context: countryCode })),
        test: function test(value) {
          if (!value) return true;
          if (typeof value !== 'string') return false;
          if (regexByCountryCode[countryCode]) return regexByCountryCode[countryCode].test(value);
          return true;
        },
      });
    }

    return schema;
  });
};

export const nameSchema = (message?: string) =>
  Yup.string()
    .trim()
    .matches(/^[A-Za-zА-Яа-яÀ-ÿ]+([-` '’][A-Za-zА-Яа-яÀ-ÿ]+)*$/, message)
    .max(30);

export const promoCodeSchema = (message?: string) =>
  Yup.string()
    .trim()
    .matches(/^[A-Za-z0-9]+([-` '’][A-Za-zА-Яа-яÀ-ÿ]+)*$/, message)
    .max(15);

export const phoneSchema = (message?: string) =>
  Yup.string()
    .trim()
    .matches(/^(?:\+?1[- ]?)?\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{4})$/, message);

export const videoUrlSchema = (message?: string) =>
  Yup.string()
    .trim()
    .url()
    .matches(/(youtube|youtu|vimeo)/, message);

export const HTMLCodeSchema = () =>
  Yup.string().transform(function transform(value) {
    return this.isType(value) && value !== null ? value.replace(/(<([^>]+)>)/gi, '') : value;
  });

export const minAgeSchema = (age: number, message?: string) =>
  Yup.date().max(subDays(subYears(new Date(), age), 1), message);

export const createDateRangeShape = (startKey: string, endKey: string) => {
  const shape = {
    [startKey]: Yup.mixed().when(endKey, (end: string, schema: Yup.AnySchema) => {
      const endDate = parseISO(end);
      if (!isValid(endDate)) return schema.nullable();
      return schema.required();
    }),
    [endKey]: Yup.mixed()
      .nullable()
      .when(startKey, (start: string, schema: Yup.AnySchema) => {
        const startDate = parseISO(start);
        if (!isValid(startDate)) return schema;
        return schema.required().test({
          name: `${startKey}_${endKey}`,
          test: (end: string) => parseISO(end) > startDate,
        });
      }),
  };

  return [shape, [startKey, endKey]];
};

const isOwnerCard = (paymentMethod: any, user: any) =>
  (paymentMethod.first_name === user.first_name && paymentMethod.last_name === user.last_name) ||
  (paymentMethod.first_name === user.last_name && paymentMethod.last_name === user.first_name);

export const is3dPartyCard = (paymentMethod: any, bayerNameData: any) => !isOwnerCard(paymentMethod, bayerNameData);
