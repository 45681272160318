import { put, takeEvery } from 'redux-saga/effects';
import { SpreedlyPaymentMethodsApi } from 'api';
import { GetAction } from 'store/types';
import { sagasHandlersFactory } from 'store/entities/utils';
import { sagasHandlersFactory as featureSagasHandlersFactory } from 'store/features/utils';
import * as actions from './actions';

const handleGetPaymentMethodsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getPaymentMethods,
  request: SpreedlyPaymentMethodsApi.getSpreedlyPaymentMethods,
  transformResponse: (response) => response.map((item: any) => ({ ...item, id: item.token })),
});

const handleSetDefaultPaymentMethodRequest = featureSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.setDefaultPaymentMethod,
  request: SpreedlyPaymentMethodsApi.setDefaultSpreedlyPaymentMethod,
  requestArgsBuilder: (action) => {
    const { payment_method_token } = action.payload.params;
    return [{ body: { payment_method_token } }];
  },
});

const handleDeletePaymentMethodRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deletePaymentMethod,
  request: SpreedlyPaymentMethodsApi.deleteSpreedlyPaymentMethods,
  requestArgsBuilder: (action) => ({
    body: {
      payment_method_token: action.payload.id,
    },
  }),
});

const handleCreatePaymentMethodsRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createPaymentMethods,
  request: SpreedlyPaymentMethodsApi.createSpreedlyPaymentMethods,
  requestArgsBuilder: (action) => ({
    body: {
      payment_method_token: action.payload.entity.id,
    },
  }),
  transformResponse: (response) => ({ id: response.token, ...response }),
});

const handleUpdatePaymentMethodsRequest = sagasHandlersFactory.createPartialUpdateOneRequestHandler({
  actions: actions.updatePaymentMethods,
  request: SpreedlyPaymentMethodsApi.updateSpreedlyPaymentMethods,
  requestArgsBuilder: (action) => ({
    body: action.payload.entity,
  }),
});

function* handleCreatePaymentMethodsSuccess(action: GetAction<typeof actions.createPaymentMethods.success>) {
  const { params, entity } = action.payload;
  const { is_default } = params;
  if (is_default) {
    yield put(
      actions.setDefaultPaymentMethod.request({
        params: { payment_method_token: entity.token },
      }),
    );
  }
}

export default function* spreedlyPaymentMethodsSagas() {
  yield takeEvery(actions.getPaymentMethods.request.type, handleGetPaymentMethodsRequest);
  yield takeEvery(actions.setDefaultPaymentMethod.request.type, handleSetDefaultPaymentMethodRequest);
  yield takeEvery(actions.deletePaymentMethod.request.type, handleDeletePaymentMethodRequest);
  yield takeEvery(actions.updatePaymentMethods.request.type, handleUpdatePaymentMethodsRequest);
  yield takeEvery(actions.createPaymentMethods.request.type, handleCreatePaymentMethodsRequest);
  yield takeEvery(actions.createPaymentMethods.success.type, handleCreatePaymentMethodsSuccess);
}
