import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { actionsFactories as featuresActionsFactories } from 'store/features/utils';
import {
  CreatePaymentMethodsType,
  PaymentMethodType,
  SetDefaultPaymentMethodParamsType,
  UpdatePaymentMethodsType,
} from './types';
import { defaultKeyWindowName } from '../shared';

export const getPaymentMethods = actionsFactories.createGetManyActionsFactory<never, PaymentMethodType>()(
  'GET_PAYMENT_METHODS_REQUEST',
  'GET_PAYMENT_METHODS_SUCCESS',
  'GET_PAYMENT_METHODS_FAILURE',
  'GET_PAYMENT_METHODS_RESET',
);

export const createPaymentMethods = actionsFactories.createCreateOneActionsFactory<any, CreatePaymentMethodsType>()(
  'CREATE_PAYMENT_METHOD_REQUEST',
  'CREATE_PAYMENT_METHOD_SUCCESS',
  'CREATE_PAYMENT_METHOD_FAILURE',
  'CREATE_PAYMENT_METHOD_RESET',
  defaultKeyWindowName,
);

export const updatePaymentMethods = actionsFactories.createUpdateOneActionsFactory<any, UpdatePaymentMethodsType>()(
  'UPDATE_PAYMENT_METHOD_REQUEST',
  'UPDATE_PAYMENT_METHOD_SUCCESS',
  'UPDATE_PAYMENT_METHOD_FAILURE',
  'UPDATE_PAYMENT_METHOD_RESET',
);

export const setDefaultPaymentMethod =
  featuresActionsFactories.createSingleFeatureActionsFactory<SetDefaultPaymentMethodParamsType>()(
    'SET_DEFAULT_PAYMENT_METHOD_REQUEST',
    'SET_DEFAULT_PAYMENT_METHOD_SUCCESS',
    'SET_DEFAULT_PAYMENT_METHOD_FAILURE',
    'SET_DEFAULT_PAYMENT_METHOD_RESET',
  );

export const deletePaymentMethod = actionsFactories.createDeleteOneActionsFactory()(
  'DELETE_PAYMENT_METHOD_REQUEST',
  'DELETE_PAYMENT_METHOD_SUCCESS',
  'DELETE_PAYMENT_METHOD_FAILURE',
);

export const setTempPaymentMethod = createAction('SET_TEMP_PAYMENT_METHOD', (paymentMethod: any) => ({
  payload: {
    paymentMethod,
  },
}));

export const resetTempPaymentMethod = createAction('RESET_TEMP_PAYMENT_METHOD');
