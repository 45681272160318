import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { PaymentStateType } from '../types';
import * as actions from '../actions';

const initialState: PaymentStateType = {
  setupFutureUsage: false,
  paidOffline: false,
  selectedPaymentMethodId: null,
};

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const handleResetState = () => initialState;

const paymentReducer = createReducer<State, Actions>(initialState, {
  [actions.setSetupFutureUsage.type]: (draft, action) => {
    draft.setupFutureUsage = action.payload.setupFutureUsage;
  },
  [actions.selectPaymentMethod.type]: (draft, action) => {
    draft.selectedPaymentMethodId = action.payload.id;
  },
  [actions.setPaidOffline.type]: (draft, action) => {
    draft.paidOffline = action.payload.paidOffline;
  },
  [actions.resetPayment.type]: handleResetState,
  [actions.resetCreatingOrder.type]: handleResetState,
});

export default paymentReducer;
