import { get, post } from './client';
import { buildPath, toJson } from './utils';
import { EVENTS_PATH, SPREEDLY, TICKET_PATH } from './constants';
import { EmailSubscribeToken, GeneralListType, IcsFeedLinkGenerator, IdType, TokenType } from './types';
import { AddOn, Donation, Event, EventList, OrderStatus, PromoCodeDisplay, TicketSessions } from './general/models';

const ORDER_PATH = 'order';
const ADDONS_PATH = 'addons';
const TICKETS_PATH = 'tickets';
const TOGGLE_FAVORITE_PATH = 'toggle-favorite';
const ICS_FEED_LINK_GENERATOR = 'ics-feed-link-generator';
const DONATION = 'donation';
const PROMOCODE_CHECK = 'promocode-check';
const ATTENDEE_CHECK = 'ticket_attendee_check';
const EMAIL_SUBSCRIBE = 'email-subscribe';
const PAY_ORDER = 'pay-order';
const SPREEDLY_PAY_ORDER = 'spreedly-pay-order';
const SETUP_OFFLINE_PAYMENT = 'setup-offline-payment';
const CHECK_PURCHASE_STATUS = 'check-purchase-status';

export const getEvent = (eventId: IdType, options: any): Promise<Event> =>
  get(buildPath(EVENTS_PATH, eventId), options).then(toJson);

export const getEvents = (options: any): Promise<GeneralListType<EventList>> =>
  get(buildPath(EVENTS_PATH), options).then(toJson);

export const getEventsList = (options: any): Promise<GeneralListType<EventList>> =>
  get(buildPath(EVENTS_PATH), options).then(toJson);

export const getEventAddons = (eventId: IdType, options: any): Promise<AddOn[]> =>
  get(buildPath(EVENTS_PATH, eventId, ADDONS_PATH), options).then(toJson);

export const getEventTickets = (eventId: IdType, options: any): Promise<TicketSessions[]> =>
  get(buildPath(EVENTS_PATH, eventId, TICKETS_PATH), options).then(toJson);

export const toggleFavoriteEvent = (eventId: IdType, options: any): Promise<any> =>
  post(buildPath(EVENTS_PATH, eventId, TOGGLE_FAVORITE_PATH), options).then(toJson);

export const createEventOrder = (eventId: IdType, options: any): Promise<OrderStatus> =>
  post(buildPath(EVENTS_PATH, eventId, ORDER_PATH), options).then(toJson);

export const payEventOrder = (eventId: IdType, orderId: IdType, options: any): Promise<OrderStatus> =>
  post(buildPath(EVENTS_PATH, eventId, PAY_ORDER, orderId), options).then(toJson);

export const spreedlyPayEventOrder = (eventId: IdType, orderId: IdType, options: any): Promise<OrderStatus> =>
  post(buildPath(EVENTS_PATH, eventId, SPREEDLY_PAY_ORDER, orderId), options).then(toJson);

export const setupEventOfflinePayment = (eventId: IdType, orderId: IdType, options: any): Promise<OrderStatus> =>
  post(buildPath(EVENTS_PATH, eventId, SETUP_OFFLINE_PAYMENT, orderId), options).then(toJson);

export const generateEventIcsFeedLinkGenerator = (eventId: IdType, options: any): Promise<IcsFeedLinkGenerator> =>
  get(buildPath(EVENTS_PATH, eventId, ICS_FEED_LINK_GENERATOR), options).then(toJson);

export const getEventDonation = (eventId: IdType, options: any): Promise<Donation> =>
  get(buildPath(EVENTS_PATH, eventId, DONATION), options).then(toJson);

export const checkPromoCode = (eventId: IdType, options: any): Promise<PromoCodeDisplay> =>
  get(buildPath(EVENTS_PATH, eventId, PROMOCODE_CHECK), options).then(toJson);

export const checkAttendee = (ticketID: IdType, options: any): Promise<any> =>
  post(buildPath(TICKET_PATH, ticketID, ATTENDEE_CHECK), options).then();

export const getEventEmailSubscription = (
  eventId: IdType,
  token: TokenType,
  options: any,
): Promise<EmailSubscribeToken> => get(buildPath(EVENTS_PATH, eventId, EMAIL_SUBSCRIBE, token), options).then(toJson);

export const createEventEmailSubscription = (
  eventId: IdType,
  token: TokenType,
  options: any,
): Promise<EmailSubscribeToken> => post(buildPath(EVENTS_PATH, eventId, EMAIL_SUBSCRIBE, token), options).then(toJson);

export const checkPurchaseStatus = (options: any): Promise<OrderStatus> =>
  get(buildPath(SPREEDLY, CHECK_PURCHASE_STATUS), options).then(toJson);
