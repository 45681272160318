import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const paymentMethodsSlice = (state: RootState) => state.entities.paymentMethods.paymentMethods;

export const getPaymentMethods = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(paymentMethodsSlice);

export const setDefaultPaymentMethodState = (state: RootState) => state.entities.paymentMethods.setDefaultPaymentMethod;

export const getPaymentMethodsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(paymentMethodsSlice);

export const hasPaymentMethods = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(paymentMethodsSlice);

export const paymentMethodsCreatingState = selectorsFactory.createCreatingStateSelector(paymentMethodsSlice);

export const paymentMethodsUpdatingState = selectorsFactory.createUpdatingStateByIdSelector(paymentMethodsSlice);

export const getTempPaymentMethod = (state: RootState) => paymentMethodsSlice(state).tempPaymentMethod;
